.carousel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .carousel__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }
  
  .carousel__content {
    position: relative;
    z-index: 2;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .carousel__container {
    width: auto;
  }
  
  .carousel__image {
    width: 600px;
    height: 600px;
    background-size: contain;
    background-position: center;
    transition: background-image 0.5s ease-in-out;
    background-repeat: no-repeat;
  }
  
  .carousel__button--left,
  .carousel__button--right {
    color: white;
    border: none;
    padding: 0px;
    cursor: pointer;
    font-size: 40px;
  }
  
  .carousel__button--left {
    margin-right: 10px;
  }
  
  .carousel__button--right {
    margin-left: 10px;
  }

  .carousel_description {
display: none;
  
  }


  .carousel_description2 {
    text-align: center;
    color: white;
    display: flex;
    width: 100%;
    justify-content: center;
      position: relative;
    z-index: 11;
    margin-top: 25px;
  }


  .close {
    position: fixed;
    top: 0;
    right: 7%;
    width: 30%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-size: 20px;
    background-color:transparent;
    color: white;
    display: flex;
    justify-content: right;
  }
  



  .carousel_button {
    display: none;
  }




  @media screen and (max-width: 767px) {

      .carousel_button_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px ;
      }

      .carousel__container {
        width: 100%;
      }

      .carousel_button {
        display: flex;
        color: white;
        border: none;
        padding: 0px;
        cursor: pointer;
        font-size: 20px;
      }

      .carousel__button--left,
      .carousel__button--right {
      display: none;
      }

      .desktop {
        display: none;
      }

      .carousel_description {
        text-align: center;
        color: white;
        display: flex;
        width: 100%;
        justify-content: center;
          position: relative;
        z-index: 11;
      }

      .carousel_description2 {
       display: none;
      }
  }

  
