html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.no-scroll {
  overflow: hidden;
}


body.no-scroll {
  height: 100vh;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-weight: bold;
}

form {
  width: 50%;
}

input,
select {
  font-family: 'Sriracha';
  height: 30px;
  width: 45%;
  border: none;
  border-bottom: 1px solid #717171;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  color: inherit;
  border-radius: 0;
}
input::placeholder {
  color: #231f20;
  opacity: 1; /* To ensure the color is fully opaque */
}

/* You can also add more specific styling for different states, like focus */
input:focus::placeholder {
  color: darkgrey;
}

textarea:focus::placeholder {
  color: darkgrey;
}


textarea::placeholder {
  color: #231f20;
  opacity: 1; /* To ensure the color is fully opaque */

}
textarea {
  all: unset;
  box-sizing: border-box; /* Ensure the padding and border are included in the element's width and height */
  width: 100%; /* Optional: set width to 100% of its container */
  height: 100px; /* Optional: give it a specific height */
  resize: none; /* Optional: prevent resizing */
  height: 60px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #717171;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  color: inherit;
  border-radius: 0;
  margin-top: 50px;
}

input[type="date"] {
  font-family: inherit; /* Use the font of the parent element */
  font-size: inherit;   /* Use the font size of the parent element */
  color: inherit;       /* Use the color of the parent element */

}

body {
  font-family: 'Sriracha';
  background-color: #faf7c6;
  box-sizing: border-box ;
  -ms-box-sizing: border-box !important;
  color: #231f20;
  font-size: 16px;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  line-height: 1.8;
}


.header,
.header2 {
  width: 100%;
  padding: 15px 35px;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #dbdcdf;
  background-color: #faf7c6;
}


a {
  text-decoration: none !important;
  color: inherit;
  margin: 0;
  padding: 0;
}


p {
  text-decoration: none !important;
  color: inherit;
}

.text-intro {
  line-height: 2;
}

.links {
margin-left: 65px;
font-weight: bold;
}

.banner {
  width: 100%;
  height: 321px;
}

.logo {
  height: 70px;
  width: auto;
}


.title {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.4;
  text-align: left;
  margin-top: 0;
}

.subtitle {
  font-size: 30px;
  margin-top: 150px;
  text-align: left;
  font-weight: bold;
}

.menu{
display: none;
}

.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #faf7c6;
}

.close-menu {
  position: fixed;
  top: 0;
  right: 10px;
  width: 30%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 20px;
  background-color: #faf7c6;
}


.menu-container-mobile{
  display: block;
}

.menu-content {
  margin: 70px 0;
  text-align: center;
}


.container{
  padding-right: 7%;
  padding-left: 7%;
 
  width: 100%;
}

.block-title {
   margin-top: 150px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.block-title-text {
  width: 50%;

}

.photo-title {
  width: 40%;
  height: 500px;
}

.service-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    line-height: 1.6;
    justify-content: space-between;
}

.service {
  margin-top: 30px;
  width: 30%;
  margin-right: 0px;
}

.service-title {
  font-weight: bold;
  margin-top: 10px;
}

.service-text {
  margin-top: 5px;
}

.service-icon {
  font-size: 40px;
}

.icon-block {  
  justify-content: center;
}

.center-icon {
  display: flex;
  justify-content: center;
  width: 100%;
}

.review-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
}

.review-block {
  max-width: 30%;
}

.review {
  margin-top: 10px;
}

.review-img {
  width: 50px;
  height: auto;
}

.review-author {
  font-weight: bold;
}

.review {
  font-size: 18px;
}

.imput-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;

}

.button-container {
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}

.contact {
  margin-top: 0px;
  margin-bottom: 40px;
}

.galery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.photo {
  width: 25%;
  border-width: 1px;
  border-color: #faf7c6;
  border: 3px solid #faf7c6;
  aspect-ratio: 1 / 1;
}


.footer {
  padding: 30px  50px ;
display: flex;
justify-content: space-between;
color: white;
background-color: #231f20;
margin-top: 150px;
}

.icon {
  font-size: 20px;
  margin-right: 5px;
}

.social {
  font-size: 30px;
  padding-top: 5px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: right;
  align-content: start;
  flex-wrap: wrap;
}


.icon-social {
  margin-left: 25px;
}

@media screen and (max-width: 767px) {
  .photo-title {
background-position: center; 
height: 200px;
width: 100%;
}

  .header,
.header2 {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  position: relative;
  z-index: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #dbdcdf;
  background-color: #faf7c6;
}

.text-intro {
  margin-bottom: 35px;
}

.banner {
  width: 100%;
  height: 200px;
}

  form {
    width: 100%;
}

.footer {
  padding: 30px 15px;
  display: flex;
  justify-content: space-between;
  color: white;
  background-color: #231f20;
  margin-top: 90px;
}

.block-title {
  margin-top: 50px;
  width: 100%;
  display: block;
  justify-content: space-between;
}

.service-wrapper {
  display: block;
  width: 100%;
  flex-wrap: wrap;
  line-height: 1.6;
  justify-content: space-between;
}

.service-title {
  margin-top: 0;
}


.service {
  margin-top: 30px;
  width: 100%;
  margin-bottom: 40px;
}

.review-block {
  max-width: 100%;
  margin-bottom: 50px;
}

.subtitle {
  margin-top: 90px;
}


.contact {
  margin-bottom: 30px;
}

.review-wrapper {
  display: block;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 80px;
}

.block-title-text {
  width: 100%;
}


.review {
  margin-top: 0px;
  font-size: 16px;
  margin-bottom: 5px;
}

.review-author {
  margin-top: 0;
}

.title {
  font-size: 25px;
}

.review-img {
  width: 35px;
}

.block-links {
  display: none;
}

.logo {
  height: 60px;
  width: auto;
}


.photo {
  width: 50%;
}

.menu{
  font-size: 20px;
   display: flex;
}



}